/* Contact Page Styles */
.contact-page {
  padding: 20px;
  text-align: center;
  background-color: #fff;
}

.contact-us-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
  margin-bottom: 30px;
}

.contact-us-form label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.contact-us-form input,
.contact-us-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.contact-us-form button {
  display: block;
  width: 100%;
  padding: 12px;
  background-color: #ff6600;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  font-weight: bold;
  box-shadow: none;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.contact-us-form button:hover {
  background-color: #e5673d;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.contact-page:focus{
  border: 2px solid #ff6600 ;
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-page {
    padding: 20px;
  }

  .contact-us-form {
    padding: 15px;
  }
}

@media (max-width: 550px) {
  .contact-us-form label {
    font-size: 14px;
  }

  .contact-us-form input,
  .contact-us-form textarea {
    font-size: 14px;
  }

  .contact-us-form button {
    padding: 10px;
    font-size: 14px;

  }
}
