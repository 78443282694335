body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  color: #525050;
}

.navbar {
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  padding: 5px;
  margin: 0;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  height: 6vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.navbar-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.navbar-logo {
  font-size: 1.5em;
  font-weight: bold;
  color: #ffffff;
}

.navbar-logo a {
  text-decoration: none;
  color: #ffffff;
}

.navbar-list {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar-item {
  margin: 0 15px;
}

.navbar-item a {
  text-decoration: none;
  color: #f4eded;
  font-size: 1.2em;
  transition: color 0.3s;
}

.search-form input {
  border-radius: 5px;
  padding: 3px
}

.navbar-item a:hover {
  color: #ff7f50;
}
