

.services-section {
  padding: 95px 100px;
  background: #f4f4f9;
  gap: 5px;
  
}

.services-section h2 {
  font-size: 2.5em;
  margin-bottom: 1em;
  color: #333;
  text-align: center;
}

.services-section p {
  text-align: center;
}


.service-cards {
  margin-top: 2em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.service-card {
  background: white;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1 1 calc(25% - 40px); /* Responsive design for four columns */
  max-width: calc(25% - 40px); /* Responsive design for four columns */
  text-align: center;
}

.service-card h3 {
  color: #ff7f50;
}