.container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
}

h1 {
  color: #333;
}

p {
  color: #666;
}
