body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  color: #333;
}

.about-container {
  text-align: center;
  padding-top: 60px; /* Adjust padding to account for fixed navbar */
  width: 100%;
}

.about-hero {
  background: url('./images/about-background.jpg') no-repeat center center;
  background-size: cover;
  position: relative;
  padding: 100px 20px;
  width: inherit;
  object-fit: cover;
  color: #ffffff;
  width: 100%;
  
}

.about-hero .overlay {
  background: rgba(237, 235, 235, 0.6); /* Black overlay with opacity */
  padding: 50px;
  border-radius: 10px;
  display: inline-block;
}

.about-hero h1 {
  font-size: 4em;
  margin-bottom: 0.5em;
}

.about-hero p {
  font-size: 1.2em;
  margin: 0.5em 0;
}

.mission-vision-section {
  display: flex;
  justify-content: space-around;
  padding: 50px 20px;
  background: #f4f4f9;
}

.mission, .vision {
  width: 45%;
  text-align: center;
}

.mission h2, .vision h2 {
  font-size: 2.5em;
  margin-bottom: 0.5em;
}

.mission p, .vision p {
  font-size: 1.2em;
  margin: 0.5em 0;
}

.values-section {
  background-color: #f0f0f0;
  padding: 20px;
  text-align: center;
}

.values-section h2 {
  margin-bottom: 20px;
  font-size: 2.5em;
}


.values-section ul {
  list-style-type: none;
  padding: 0;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}

.values-section li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
}

.values-section li:before {
  content: "•";
  position: relative;
  left: 0;
  color: #333;
}

.team-section {
  padding: 50px 20px;
  background: #f4f4f9;
}

.team-section h2 {
  font-size: 2.5em;
  margin-bottom: 0.5em;
}

.team-member {
  display: inline-block;
  width: 200px;
  margin: 20px;
  text-align: center;
}

.team-member img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.team-member h3 {
  font-size: 1.2em;
  margin: 10px 0 5px;
}

.team-member p {
  font-size: 1em;
  margin: 5px 0;
}
