body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  color: #333;
}

.home-container {
  text-align: center;
  padding-top: 60px; /* Adjust padding to account for fixed navbar */
}

.hero-section {
  background-size: cover;
  background-position: center;
  position: relative;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  text-align: center;
}

.hero-section .overlay {
  background: rgba(249, 247, 247, 0.7); /* Black overlay with opacity */
  padding: 50px;
  border-radius: 10px;
  width: 90%;
}

.hero-section h1 {
  font-size: 4em;
  margin-bottom: 0.5em;
}

.hero-section p {
  font-size: 1.2em;
  margin: 0.5em 0;
}

.cta-button {
  background-color: #ff7f50;
  color: white;
  padding: 15px 30px;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 20px;
  display: inline-block;
  font-size: 1em;
}

.cta-button:hover {
  background-color: #e5673d;
}

.services-section {
  padding: 50px 20px;
  background: #f4f4f9;
}

.services-section h2 {
  font-size: 2.5em;
  margin-bottom: 1em;
  color: #333;
}

.service-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.service-card {
  background: white;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1 1 calc(25% - 40px); /* Responsive design for four columns */
  max-width: calc(25% - 40px); /* Responsive design for four columns */
  text-align: center;
}

.service-card h3 {
  color: #ff7f50;
}

.footer-section {
  background: #333;
  color: white;
  padding: 20px;
}

.footer-section p {
  margin: 0;
  font-size: 0.9em;
}
