.container {
  padding: 100px;
  max-width: 100px;
  margin: auto;
}

.projects-section {
  padding :157px 100px;
  background: #FBFBFB;
  gap: 5px;
  
}

.projects-section h1 {
  font-size: 2.5em;
  margin-bottom: 1em;
  color:rgb(8, 9, 9) ;
  text-align: center;
}

.projects-cards {
  margin-top: 2em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.projects-card {
  background:  #f4f4f9;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1 1 calc(25% - 40px); /* Responsive design for four columns */
  max-width: calc(25% - 40px); /* Responsive design for four columns */
  text-align: center;
}

.projects-card h2 {
  color: rgb(8, 9, 9);
}